.GridView {
  text-align: center;
  background-color: white;
  min-height: 100vh;
}

.GridView table {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;

}

.GridView table tr td:first-child {
  width: 25%;
  text-align: left;
  font-size: medium;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: gray;
}

.GridView table tr td:last-child {
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: gray;
}

.GridView tbody tr:nth-child(odd) {
  background-color: #f0f0fb;
}

.GridView tbody tr:nth-child(5n+0) {
  border-bottom: 2px solid black;
}

.GridView thead {
  background-color: #e5e6e7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: gray;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: gray;
}

.GridView tfoot {
  background-color: #e5e6e7;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: gray;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: gray;
}

.GridView table {
  border-collapse: collapse;
}

.GridView table th td {
  border: 1px solid gray;
}

.eventCell {
  border-left: 1px solid gray;
}

.GridView td {
  width: 6%;
  text-align: center;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    background-color: white !important;
    min-height: 100vh;
  }
}