.reportBox {
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 5px;
}

.reportBox table {
    width: 100%;
}

.reportBox table thead th:first-child {
    width: 50%;
    text-align: left;
    font-size: small;
}

.reportBox table thead th+th {
    width: 25%;
    text-align: center;
    font-size: small;
}

.reportBox table thead th+th+th {
    width: 25%;
    text-align: center;
    font-size: small;
}

.reportBox table tr td:first-child {
    width: 50%;
    text-align: left;
    font-size: small;
}

.reportBox table tr td+td {
    width: 25%;
    text-align: right;
    font-size: small;
}



.reportGroup:before,
.reportGroup:after {
    content: "";
    display: table;
}

.reportGroup:after {
    clear: both;
}

.reportGroup {
    zoom: 1;
    /* For IE 6/7 */
}

.reportSection {
    clear: both;
    padding: 0px;
    margin: 0px;
}

.reportCol {
    display: block;
    float: left;
    margin: 1px;
}

.reportCol:first-child {
    margin-left: 3;
}

.span_3_of_12 {
    width: 24.25%;
}

.report {
    font-size: small;
    background-color: white;
    text-align: center;
    min-height: 100vh;
}

.report div {
    margin-top: 5px;
}

.report h3 {
    background-color: azure;
    border-bottom: 1px solid black;
    margin-top: 1px;
    margin-bottom: 1px;
    text-align: center;
    font-size: small;
    font-weight: 800;
    line-height: normal;
}

.report h2 {
    margin-top: 1px;
    margin-bottom: 1px;
    text-align: center;
}

.report table thead tr td {
    border: none;
}

.report table tbody tr td {
    border: none;
}

.report table tfoot tr td {
    border: none;
}

.report table tfoot {
    border-top: 1px solid black;
}

.report table thead {
    border-bottom: 1px solid lightgray;
}

/*  GO FULL WIDTH BELOW 480 PIXELS */
@media only screen and (max-width: 480px) {
    .col {
        margin: 0% 0 0% 0%;
    }

    .span_1_of_12,
    .span_2_of_12,
    .span_3_of_12,
    .span_4_of_12,
    .span_5_of_12,
    .span_6_of_12,
    .span_7_of_12,
    .span_8_of_12,
    .span_9_of_12,
    .span_10_of_12,
    .span_11_of_12,
    .span_12_of_12 {
        width: 100%;
    }
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
        background-color: white !important;
        min-height: 100vh;
    }
}