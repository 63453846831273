.form-popup {
  display: block;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 1px solid #160101;
  border-radius: 5px;
  z-index: 9;
  background: #f1f1f1;
}

.form-popupLHS {
  display: block;
  position: fixed;
  bottom: 0;
  left: 15px;
  border: 1px solid #160101;
  border-radius: 5px;
  z-index: 9;
  background: #f1f1f1;
}

.form-container .btn {
  background-color: #4CAF50;
  color: white;
  padding: 6px 2px;
  border: 10px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}